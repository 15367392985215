import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import '../style/Information.css';
import constantList from '../constant.js';

class Information extends Component {
  constructor() {
    super();
    this.state = {
      isHidden: true
    };
  }

  Info = () => {
    var host = window.location.host;
    var userName = document.getElementsByClassName('userName')[0].value;
    var emailID = document.getElementsByClassName("emailID")[0].value;
    var fullurl = constantList.RDS_URL + "Section=0" + "&userName=" + userName + "&emailID=" + emailID + "&url=" + host;
    alert(fullurl);
    fetch(fullurl)
      .then(
        browserHistory.push('/customerserviceexperience')
      );
  }

  render() {
    return (
      <div className="customer-service-experience">

        <form className="thumb-section">

          <h4 className="textleft mb-20">Name</h4>
          <input type="text" className="userName mb-50" />

          <h4 className="textleft mb-20">Email ID</h4>
          <input type="text" className="emailID mb-50" />

          <button className="btn btn-lg btn-primary btn-info" type="submit" value="Submit" onClick={this.Info}>Next</button>
        </form>

      </div>
    );
  }
}

export default Information;
