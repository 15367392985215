import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReviewStars from '../src/components/ReviewStars.js';
import CustomerServiceExperience from '../src/components/CustomerServiceExperience.js';
import Satisified from '../src/components/Satisified.js';
import Convenient from '../src/components/Convenient.js';
import ThankYou from '../src/components/ThankYou.js';
import logo from '../src/images/white-kcf-logo.jpg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img className="img-fluid" src={logo} alt="logo" />
        </header>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default App;
