import React from 'react';
import ReactDOM from 'react-dom';
import {
  Router,
  Route,
  Link,
  BrowserRouter,
  IndexRoute,
  hashHistory,
  browserHistory
} from 'react-router';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux';
import './index.css';
import App from './App';
import ReviewStars from '../src/components/ReviewStars.js';
import Information from '../src/components/Information.js';
import CustomerServiceExperience from '../src/components/CustomerServiceExperience.js';
import Satisified from '../src/components/Satisified.js';
import Convenient from '../src/components/Convenient.js';
import ThankYou from '../src/components/ThankYou.js';

ReactDOM.render(
  <Router history={browserHistory}>
    <div>
      <Route path="/" component={App}>
        <IndexRoute component={ReviewStars} />
        <Route path="home/:Sandeep" component={ReviewStars} />
        <Route path="information" component={Information} />
        <Route path="customerserviceexperience" component={CustomerServiceExperience} />
        <Route path="satisified" component={Satisified} />
        <Route path="convenient" component={Convenient} />
        <Route path="thankyou" component={ThankYou} />
      </Route>
    </div>
  </Router>,
  document.getElementById('root')
);
