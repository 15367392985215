import React, { Component } from 'react';
import 'react-app-polyfill/ie11';


import {
  browserHistory
} from 'react-router';

import constantList from '../constant.js';
import '../style/ReviewStars.css';

class ReviewStars extends Component {

  constructor(props) {
    var emailID;
    super(props);
    emailID = this.props.params;
    console.log(emailID);
  }
  // This binding is necessary to make `this` work in the callback
  reviewSubmit(event) {

    //To get the current selected start value
    function getRadioCheckedValue(radio_name) {
      var oRadio = document.forms[0].elements[radio_name];
      for (var i = 0; i < oRadio.length; i++) {
        if (oRadio[i].checked) {
          return oRadio[i].value;
        }
      }
      return 0;
    }
    //To store the oppId in localStorage
    function getUrlVars() {
      var vars = {};
      var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
      });
      return vars;
    }
    var url_obj = getUrlVars();
    // Get Host 
    var host = window.location.host;
    var oppId = url_obj.oppId ? url_obj.oppId : 0;
    var firstName = url_obj.first_name ? url_obj.first_name : 'NA';
    var lastName = url_obj.last_name ? url_obj.last_name : 'NA';
    localStorage.setItem("oppId", oppId);
    var getCheckedValue = getRadioCheckedValue('rating');
    if (getCheckedValue == 0) {
      alert("Please rate your experience.");
      event.preventDefault();
      return false;
    } else if (getCheckedValue > 3) {
      fetch(constantList.RDS_URL + "Stars=" + getCheckedValue + "&review_page=1&firstName=" + firstName + "&lastName=" + lastName + "&oppId=" + oppId + "&url=" + host)
        .then()
      event.preventDefault();
      setTimeout(function () { window.location = constantList.KCF_GOOGLE_REVIEW_PAGE; }, 2000);
    } else {
      event.preventDefault();
      fetch(constantList.RDS_URL + "Stars=" + getCheckedValue + "&review_page=1&firstName=" + firstName + "&lastName=" + lastName + "&oppId=" + oppId + "&url=" + host).then(
        browserHistory.push('/customerserviceexperience')
      );
    }
  }

  render() {
    return (
      <div className="star Star_custom">
        <h1>How would you rate your experience with <br /> Knight Capital Funding?</h1>
        <form className="Button_center">
          <div className="rating">
            <input type="radio" id="star5" name="rating" value="5" />
            <label className="full star-rating" htmlFor="star5" />
            <input type="radio" id="star4" name="rating" value="4" />
            <label className="full star-rating" htmlFor="star4" />
            <input type="radio" id="star3" name="rating" value="3" />
            <label className="full star-rating" htmlFor="star3" />
            <input type="radio" id="star2" name="rating" value="2" />
            <label className="full star-rating" htmlFor="star2" />
            <input type="radio" id="star1" name="rating" value="1" />
            <label className="full star-rating" htmlFor="star1" />
          </div>
          <button className="btn btn-lg btn-primary submit" onClick={this.reviewSubmit}>
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default ReviewStars;
