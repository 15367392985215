import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import '../style/CustomerServiceExperience.css';
import thumbsupactive from '../images/thumbs-up-active.png';
import thumbsupinactive from '../images/thumbs-up-inactive.png';
import thumbsdownactive from '../images/thumbs-down-active.png';
import thumbsdowninactive from '../images/thumbs-down-inactive.png';
import constantList from '../constant.js';

class CustomerServiceExperience extends Component {
  constructor() {
    super();
    this.state = {
      isHidden: true,
      isHidden1: true
    };

  }
  CusSerExSubmit = () => {
    var host = window.location.host;
    var oppId = localStorage.getItem('oppId');
    var yesAnswer = "User gave positive feedback.";
    var element = document.getElementsByClassName('thumbs-up-active')[0];
    var element_inactive = document.getElementsByClassName('thumbs-down-active')[0];
    if (element.classList.contains('hidden') && element_inactive.classList.contains('hidden')) {
      alert("Please rate your experience.");
      return false;
    }
    if (element.classList.contains('hidden')) {
      var userDescription = document.getElementsByClassName('user-description')[0].value;
      var fullurl =
        constantList.RDS_URL +
        'review_page=2&Customer=No' +
        '&oppId=' +
        oppId +
        '&CususerDescription=' +
        userDescription + "&url=" + host;
      console.log(fullurl);
      fetch(fullurl).then(browserHistory.push('/Satisified'));

    } else {
      var userDescriptionUp = document.getElementsByClassName('user-description-up')[0].value;
      fetch(
        constantList.RDS_URL +
        '&review_page=2&Customer=Yes' +
        '&oppId=' +
        oppId +
        '&CususerDescription=' +
        userDescriptionUp + "&url=" + host

      ).then(browserHistory.push('/Satisified'));
    }
  };
  thumbsUpActive(event) {
    //Do Nothing
    event.preventDefault()
  }
  thumbsUpInactive(event) {
    if (this.state.isHidden == true) {
    } else {
      this.setState(state => ({
        isHidden: !this.state.isHidden
      }));
    }
    this.setState(state => ({
      isHidden1: !this.state.isHidden1
    }));
    //ThumbsUp Events
    document.getElementsByClassName('thumbs-up-inactive')[0].classList.add('hidden');
    document.getElementsByClassName('thumbs-up-active')[0].classList.remove('hidden');
    //ThumbsDown Events
    document.getElementsByClassName('thumbs-down-inactive')[0].classList.remove('hidden');
    document.getElementsByClassName('thumbs-down-active')[0].classList.add('hidden');
  }
  thumbsDownActive(event) {
    //Do Nothing
  }
  thumbsDownInactive(event) {
    if (this.state.isHidden1 == true) {
    } else {
      this.setState(state => ({
        isHidden1: !this.state.isHidden1
      }));
    }
    this.setState(state => ({
      isHidden: !this.state.isHidden
    }));
    //ThumbsDown Events
    document.getElementsByClassName('thumbs-down-inactive')[0].classList.add('hidden');
    document.getElementsByClassName('thumbs-down-active')[0].classList.remove('hidden');
    //ThumbsUp Events
    document.getElementsByClassName('thumbs-up-inactive')[0].classList.remove('hidden');
    document.getElementsByClassName('thumbs-up-active')[0].classList.add('hidden');
  }
  render() {
    return (
      <div className="customer-service-experience">
        <h1 className="mb-50"> How was your customer <br />service experience?</h1>
        <form className="thumb-section">
          <div className="thumbs-up-down-section">
            <img
              onClick={this.thumbsUpActive}
              className="thumbs-up-active hidden mr-100px"
              src={thumbsupactive} />
            <img
              onClick={this.thumbsUpInactive.bind(this)}
              className="thumbs-up-inactive mr-100px"
              src={thumbsupinactive} />
            <img
              onClick={this.thumbsDownInactive.bind(this)}
              className="thumbs-down-inactive"
              src={thumbsdowninactive} />
            <img
              onClick={this.thumbsDownActive}
              className="thumbs-down-active hidden"
              src={thumbsdownactive} />
          </div>
          {!this.state.isHidden && <Child />}
          {!this.state.isHidden1 && <Child2 />}
          <br />
          <button
            className="btn btn-lg btn-primary"
            type="button"
            value="Submit"
            onClick={this.CusSerExSubmit}>
            Next
          </button>
        </form>
        <div class="pagination-section">
          <p class="pagination-section-text">1 of 3</p>
        </div>
      </div>
    );
  }
}
const Child = () => <textarea type="text" cols="52" rows="6" placeholder="How can we improve?" className="user-description" />;
const Child2 = () => <textarea type="text" cols="52" rows="6" placeholder="Tell us about your experience…" className="user-description user-description-up" />;

export default CustomerServiceExperience;
