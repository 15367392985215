import React, { Component } from 'react';
import '../style/ThankYou.css';

class ThankYou extends Component {

  render() {
    return (
      <div className="thank-you-section">
        <h1 className="font-size-xlarge pb-50">Thank you!</h1>
        <p className="font-size-medium">Your feedback is important to us and helps us create the best experience possible. Please feel free to contact us at (855) 462-4249 if you have any questions. </p>
      </div>
    );
  }
}
export default ThankYou;